<template>
  <div class="mobileIndex">
    <div v-if="isWeChatPage" class="home">
      <div class="arrow">
        <img class="img1" src="../../assets/mobile/img/arrow.png" />
      </div>
      <div class="title1">
        <div class="icon">
          <span class="num"> 1 </span>
        </div>
        <p class="icon-front">点击右上角的</p>
        <div class="box1">
          <p class="icon-dot">...</p>
        </div>
        <p class="icon-front">按钮</p>
      </div>
      <div class="title1">
        <div class="icon">
          <span class="num"> 2 </span>
        </div>
        <p class="icon-front">选择</p>
        <div class="box2">
          <p class="icon-front">在浏览器中打开</p>
        </div>
      </div>
    </div>
    <div v-else class="no-wechat">
      <div class="top-content">
        <div class="logo-box">
          <img class="logo-img" src="./logo.png">
        </div>
        <div class="top-text">懂家校更懂您的需要</div>
      </div>
      <div class="middle-content">
        <div class="middle-tetx">专注幼教行业，致力打造具有前瞻性的家校共育系统</div>
        <div class="middle-btn">
          <div class="down-btn yellowbtn">家长端APP下载</div>
          <div class="down-btn bullbtn" @click="getVersion">园丁端APP下载</div>
        </div>
        <div class="middle-tag">COPYRIGHT&nbsp;©&nbsp;2019-2022&nbsp;成都在线支点教育科技有限公司<br>
          ALL&nbsp;RIGHTS&nbsp;RESERVED.&nbsp;蜀ICP备20023331号</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import micell from 'micell'
import qs from 'qs'
export default {
  data() {
    return {
      androidMsg: {},
      type: '',
      iosUrl: '',
      //ydIos: 'https://apps.apple.com/cn/app/%E9%87%91%E6%A0%91%E4%B8%AB/id1560759387#?platform=iphone',
      ydIos: 'itms-apps://itunes.apple.com/app/id1593326584',
      jzIos: 'itms-apps://itunes.apple.com/app/id1560759387',
      jzInfo: {},
      ydInfo: {},
      isWeChatPage:true//判断是否是微信
    };
  },
  created() {
    this.getVersion();
    const params =qs.parse(location.search.split('?')[1])
    this.type = params.type
    this.iosUrl = this.type === 'yd'?this.ydIos:this.jzIos
  },
  methods: {
    async getVersion() {
      axios
          .get("https://api.v2.jinshuy.com/mamserver/api/version/latest/Android?currVersion=0")
          .then((res) => {
            // console.log(res.data.data)
            res.data.data.forEach(item => {
              if(item.appSide.name === 'PARENT'){
                console.log('家长',item)
                this.jzInfo = item
              }
              if(item.appSide.name === 'TEACHER'){
                console.log('园丁',item)
                this.ydInfo = item
              }
            })
            this.isWeixin()
          });
    },
    isWeixin() {
      if (micell.ua.isWeChat()) {
        return true;
      } else {
        this.isAndroid();
      }
    },
    isAndroid() {
      if (micell.ua.isAndroid()) {
        this.isWeChatPage =false
        this.jumpUrl(this.type === 'yd'?this.ydInfo.apkPath:this.jzInfo.apkPath)
        
        
      } else {
        this.isWeChatPage =false
        this.jumpUrl(this.iosUrl)
      }
      // if (micell.ua.isIOS()) {
      //   this.jumpUrl(this.iosUrl)
      // }
    },

    jumpUrl(url) {
      location.href = url
    }
  },
};
</script>
<style scope lang="less">
html,
body {
  width: 100%;
  height: 100%;
}

.mobileIndex {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .home {
    background: url("../../assets/mobile/img/download.png") no-repeat;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../assets/mobile/img/download.png', sizingMethod='scale');
  }

  .arrow {
    width: 100%;

    .img1 {
      display: block;
      width: 80px;
      margin-left: 240px;
    }
  }

  .title1 {
    width: 100%;
    height: 80px;
    display: flex;
  }

  .icon {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 50%;
    margin-left: 12%;
    opacity: 1;
  }

  .num {
    width: 26px;
    height: 62px;
    font-size: 25px;
    font-family: PingFang SC;
    font-weight: 800;
    margin-left: 10px;
    color: #ff8f44;
    opacity: 1;
  }

  .icon-front {
    height: 59px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-top: 2px;
    margin-left: 2px;
    opacity: 1;
  }

  .box1 {
    width: 50px;
    height: 35px;
    background: #000000;
    margin-top: 0px;
    opacity: 0.5;
    border-radius: 11px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .box2 {
    margin-left: 5px;
    width: 175px;
    height: 35px;
    background: #000000;
    margin-top: 0px;
    opacity: 0.5;
    border-radius: 11px;
    padding-left: 10px;
  }

  .icon-dot {
    width: 36px;
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-left: 10px;
    margin-top: -21px;
    display: block;
    padding: 0;
  }
}

.no-wechat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top-content {
    width: 100%;
    height: 232px;
    padding-top: 50px;
    background: url('./topback.jpg') no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .logo-img {
      width: 81px;
      height: 117px;
      margin-bottom: 15px;
    }

    .top-text {
      width: 100%;
      font-size: 34px;
      font-weight: bold;
      color: #4C2609;
      text-align: center;
    }
  }

  .middle-content {
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    color: #30290E;
    font-size: 13px;
    text-align: center;
    background: url('./bottomback.jpg') no-repeat;
    background-size: 100%;
    background-position: 0 100%;

    .middle-tag {
      font-size: 10px;
    }

    .middle-btn {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0;

      .down-btn {
        width: 230px;
        height: 64px;
        line-height: 64px;
        font-size: 20px;
        font-weight: bold;
        border-radius: 10px;
      }

      .yellowbtn {
        background-color: #FBD646;
        color: #1E1E1E;
      }

      .bullbtn {
        background-color: #3C90FE;
        color: #FFFFFF;
        margin-top: 25px;
      }
    }
  }

  .bottom-content {
    width: 100%;
    height: 27%;
    background: url('./bottomback.jpg') no-repeat;
    background-size: cover;
  }
}
</style>
